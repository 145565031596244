.dropdown-styled {
  background-color: white;
  border-radius: 0px;
}

.dropdown-toggle {
  @extend .dropdown-styled;
  border: 0.1px solid $secondary;
  height: 2.5em;
  padding: 0.25rem 0.5rem;
}

.dropdown-menu {
  @extend .dropdown-styled;

  padding: 10px;

  &.borderless-dropdown {
    border: 0.1px solid $secondary;
    padding: 0px;
    :last-child {
      border-bottom: none;
    }
  }
}

.dropdown-item {
  flex: 1;
  padding: 10px;
  &:hover {
    background-color: $palePrimary;
    border-radius: 5px;
  }

  &.borderless-dropdown {
    padding: 0.25rem 0.5rem;
    border-bottom: 0.1px solid $secondary;
    &:hover {
      border-radius: 0px;
    }
  }
}
