:root {
  @each $size, $length in $spacers {
    --sp-#{$size}: #{$length};
    --sp-n#{$size}: -#{$length};
  }

  --sidebar-width-xs: 175px;
  --sidebar-width-sm: 260px;
  --sidebar-width-lg: 460px;

  --student-card-pop: 1px;
  --student-grid-extra-padding: var(--student-card-pop);
  --student-card-width: 9rem;
  --student-card-height: 6rem;
  --student-card-gap: 16px;
  --student-card-full-size: calc(var(--student-card-width) + var(--student-card-gap));

  --student-partition-header-width: 185px;
  --student-partition-delete-width: 1.5rem;

  $student-grid-row-counts: (
    "1900px": 6,
    "1550px": 5,
    "1350px": 4,
    "1150px": 3,
    "950px": 2,
  );

  --student-grid-row-count: 8;
  @each $size, $count in $student-grid-row-counts {
    @media (max-width: #{$size}) {
      --student-grid-row-count: #{$count};
    }
  }

  $partition-grid-row-counts: (
    "2050px": 7,
    "1900px": 6,
    "1650px": 5,
    "1500px": 4,
    "1350px": 3,
    "1200px": 2,
    "1050px": 1,
  );

  --partition-grid-row-count: 8;
  @each $size, $count in $partition-grid-row-counts {
    @media (max-width: #{$size}) {
      --partition-grid-row-count: #{$count};
    }
  }
}
