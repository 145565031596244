// Override default variables before the import
$theme-colors: (
  "primary": #5a7dfd,
  "secondary": #363636,
  "success": #19b83d,
);

// Import Bootstrap and its default variables
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// Create variables before custom import
$changesMade: #ffb673;
$palePrimary: #d6e1ff;
$paleSecondary: #acacac;

$sidebar-width: 190px;

// Import custom made scss
@import "scss/inlineEdit.scss";
@import "scss/modals.scss";
@import "scss/dropdown.scss";
@import "scss/form.scss";
@import "scss/tabs.scss";
@import "scss/sizing.scss";

@font-face {
  font-family: "LEMON MILK Pro";

  src: url(../assets/fonts/LEMONMILKPro-Regular.woff) format("woff"),
    url(../assets/fonts/LEMONMILKPro-Regular.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "LEMON MILK Pro";
  src: url(../assets/fonts/LEMONMILKPro-SemiBold.woff2) format("woff2"),
    url(../assets/fonts/LEMONMILKPro-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "LEMON MILK Pro", Arial, sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.clip-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pagination {
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.p {
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
}

//fix for date picker in safari
.react-datepicker__navigation-icon {
  width: 0;
}
