// Flexible
.inline-editable-view {
  text-align: center;
  margin: 0px;
  font-weight: 600;
}

.inline-editable-edit {
  width: 95%;
  border-width: 1px;
  border-radius: 0px;
}
