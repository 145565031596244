.form-control {
  border: 1px solid $secondary;
  border-radius: 0px;
  height: 2.5em;
  font-size: 14px;
}

input {
  // Prevents autofill from changing font and text size, says this doesn't exist but it works
  webkit-autofill: first-line;
}

.input-group {
  .input-group-text {
    border-radius: 0px;
    border: 0.1px solid $secondary;
    background-color: $secondary;
    color: white;
  }
  .form-control {
    border: 0.1px solid $secondary;
    border-radius: 0px;
    height: 2.5em;
    font-size: 1rem;
  }
}

.form-column-space {
  gap: 35px;
}
