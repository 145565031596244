// Styles for all modals
.modal-content {
  border-radius: 0% !important;
}

// Custom modals
.full-width-modal {
  margin-top: 7em !important;
  max-width: 70em !important;
}

.tag-filter-modal {
  margin-left: calc(15% + #{$sidebar-width}) !important;
  margin-right: 15% !important;
  max-width: 55em !important;
  min-width: 55em !important;
}

.modal.show.fullscreen .modal-dialog {
  max-width: 100%;
  max-height: 100%;
  margin: 0;

  .modal-content {
    max-height: 100vh;
  }
}

@each $name, $size in $grid-breakpoints {
  @include media-breakpoint-down($name, $grid-breakpoints) {
    .modal.show.fullscreen-#{$name} .modal-dialog {
      max-width: 100%;
      max-height: 100%;
      margin: 0;

      .modal-content {
        max-height: 100vh;
      }
    }
  }
}

.question-set-link-share-modal {
  margin-left: 30% !important;
  margin-right: 30% !important;
  max-width: 100% !important;
  * {
    border-bottom: none !important;
  }
  .modal-header {
    padding-bottom: 0 !important;
  }
}

.width-1000px-modal {
  margin-top: 100px !important;
  max-width: 1000px !important;
  align-self: center !important;
}

.ultra-wide-modal {
  max-width: 1000px !important;
}

.width-700px-modal {
  margin-top: 100px !important;
  max-width: 700px !important;
  align-self: center !important;
}

.confirm-modal-backdrop {
  z-index: 1200;
}
.confirm-modal {
  z-index: 1500;
  p {
    margin-bottom: var(--sp-3);
  }
}
