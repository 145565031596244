.nav-tabs {
  border-bottom-color: $secondary;

  .nav-link {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
  }

  .nav-link.active {
    border-color: $secondary $secondary white;
  }
  .nav-link:hover.active {
    border-color: $secondary $secondary white;
  }

  .nav-link:hover {
    border-color: $paleSecondary $paleSecondary $secondary $paleSecondary;
  }
}
